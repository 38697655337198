// Planets styles

@import mixins
@import colors

@import url("https://use.typekit.net/pgb8fvx.css")
@import url("https://fonts.googleapis.com/css?family=Varela+Round")

/*
 * GENERAL DEFAULTS
 */
#planets:root
  --gap: 0px
  --button-radius: 15px
  --banner-block-size: calc(30px + 3 * var(--padding))
  --front-banner-block-size: var(--banner-block-size)

  .tk-soleil, body, input, button, textarea, select, label
    font-family: 'soleil', sans-serif

  body
    margin-inline: auto
    background: none

  meter
    background: transparent
    &::-webkit-meter-bar
      background: transparent
    &::-webkit-meter-suboptimum-value
      background: gold lighten-gradient(gold) no-repeat

  /*
   * TEXT STYLES
   */
  h2, h3, h4, h5
    font-family: 'Varela Round', sans-serif
    text-shadow: 1px 1px 2px white

  h2
    font-size: 20px

  h3
    font-size: 18px

  h4
    font-size: 16px

  h5
    font-size: 14px

  pre, code, blockquote
    background-color: lighten($planet_fill, 4 * $step)

  /*
   * LINKS & BUTTONS
   */
  a, button.link
    font-size: inherit
    color: black
    &:hover
      color: gold

  /*
   * FORM FIELDS
   */
  input, button, textarea, select, label, legend
    font-size: 13px
    text-transform: inherit

  textarea, label, legend
    line-height: 14px

  input[type="text"], input[type="password"], input[type="email"], input[type="search"], input[type="number"], input[type="url"], input[type="date"], input[type="file"]::file-selector-button, textarea, select
    border: none
    border-inline-end: 1px solid gold
    border-block-end: 1px solid gold
    min-block-size: 24px
    padding-inline: 4px
    background-color: white

  input[type="file"]::file-selector-button
    padding-block: 4px

  input[type="radio"], input[type="checkbox"]
    inline-size: 18px
    block-size: 18px

  /*
   * COMMON ELEMENTS
   */
  .blurred
    filter: blur(2px)

  /*
   * LAYOUT STYLES
   */
  /* main container for all pages */
  #front_page, #page

    /* content sections */
    #front_content, #content
      inline-size: auto
      background: none
      /* general header - the site title, welcome message and login/logout button - occasionally also test bar and messages */
      header#header
        inline-size: 100%
        background: transparent
        aside#messages
          font-size: 14px
          @each $selector, $text_color, $color in '#flashnotice' black gold, '#flashalert' white $outerspace
            #{$selector}
              color: $text_color
              background: $color
        #banner
          padding-inline: calc(var(--padding) - var(--gap))
          background: white
          color: $outerspace
          #sidebar_button button
            @media (max-width: 555px) and (max-height: 999px), (max-width: 999px) and (max-height: 555px)
              line-height: 21px
          > a
            font-size: 24px
            color: $outerspace
            &:hover
              color: gold
          > span
            font-size: 15px
            line-height: 15px
            a
              text-transform: lowercase
              color: white
          .button a, .button button
            color: black
            text-shadow: 1px 1px 2px white
            border-color: gold
            background: gold
            &:hover
              border-color: gold
              background: darken(gold, $half-step)
            &:active
              border: 1px inset gold
              background: gold
            img
              display: none

      /* main content */
      main
        max-block-size: 100%
        padding-block-start: var(--front-banner-block-size)
        background: lighten($planet_fill, 3 * $step)

        /* project presentation for front & about pages */
        section#project_information
          max-block-size: 100%
          padding: 0px
          font-size: 16px
          color: $deepgray
          h4
            margin-block: 0px
          h4, h5
            text-shadow: 1px 1px 2px white
          button.button
            display: none
          p
            text-align: left
          a
            color: gold
            &:hover
              color: black
          section#stars
            position: relative
            min-block-size: 100%
            padding-inline: calc(2 * var(--padding))
            display: flex
            flex-flow: column
            justify-content: center
            background: $outerspace url("planets/stars_rocket.png") center top -50px
            background-size: auto 100%
            text-align: center
            header
              margin: var(--padding)
              background: transparent
              h4
                font-size: 40px
                line-height: normal
                color: white
                text-shadow: 1px 1px 2px black
            img
              position: absolute
              inset-inline: 0px
              inset-block-end: 0px
              margin: auto
              inline-size: 23.7vh
              block-size: 25vh
            button.button, a.button
              box-shadow: 0px 2px 2px $deepgray
          .tutors .images .image
            max-inline-size: 1000px
          section.gamification, section.testimonials, section.investigators
            padding: calc(2 * var(--padding))
            background-color: $brightwhite
            img
              min-inline-size: 100px
              max-inline-size: 100%
              max-block-size: 300px
            h4
              margin-block: calc(2 * var(--padding))
              font-size: 24px
            .columns
              align-items: center
              .column + .column
                position: relative
                margin: 0px
                flex: 2 2 0px
                padding-inline-start: calc(2 * var(--padding))
                p
                  font-size: 18px
          section.instructors, section.students
            background-color: white
            text-align: center
            h4
              padding: calc(2 * var(--padding))
              text-align: left
              font-size: 28px
            h5
              font-size: 18px
            hr
              border: 0px
              block-size: 3px
              background-color: gold
            img
              max-inline-size: 100%
              max-block-size: 200px
            .columns
              padding: calc(2 * var(--padding))
          section.testimonials
            h4
              @include reader-visible
            .open_quote, .close_quote
              position: absolute
              min-inline-size: var(--padding)
              max-inline-size: 12%
              max-block-size: calc(4 * var(--padding))
            .open_quote
              inset-inline-start: 0px
              inset-block-start: calc(-4 * var(--padding))
            .close_quote
              inset-inline-end: 0px
              inset-block-end: calc(-4 * var(--padding))
          section.investigators h4
            margin-block-start: 0px
            font-size: 18px
        .side + .side > *
          margin-inline-start: 0px
        section.panel
          border: none
          font-size: 13px
        form
          section.filters
            select, input
              border-radius: calc(var(--button-radius) / 2)
            button img
              block-size: 20px
            input
              border-radius: 13px
              padding-inline: 5px
          section.panel
            .row .cell
              line-height: 14px
            \:is(input, textarea, select):invalid
              box-shadow: 0px 0px 1.5px 2px orange
            \:is(.table, .logging) label
              text-transform: lowercase
        section.list
          border: none
          > .table
            background-color: transparent
            \:is(.thead, .tfoot) .tr
              background: $planet_fill
              text-shadow: 1px 1px 2px $deepgray
              text-transform: lowercase
            .thead .tr
              margin-block-end: var(--padding)
            .tfoot .tr
              margin-block-start: var(--padding)
              .table .cell span
                inline-size: calc(100% - 23px)
            .tbody
              border-radius: var(--button-radius)
              background-color: lighten($planet_fill, 3 * $step)
              .tr:not(:last-child)
                margin-block-end: var(--padding)
            .rowgroup.ui-sortable
              border-radius: var(--button-radius)
              &:nth-child(n + 2)
                margin-block-start: var(--padding)
              .tr
                margin: var(--padding)
            .tr
              border-radius: var(--button-radius)
              padding-inline: var(--padding)
              background-clip: border-box
            @each $class, $color in 'selected.row_odd' lighten($planet_fill, 4 * $step), 'selected.row_even' lighten($planet_fill, 3 * $step + $half-step)
              .tr.#{$class}
                background-color: $color
            .td, .th
              font-size: 13px
              img.inline_icon
                block-size: 18px
              &.icon
                line-height: 13px
              @each $class, $inline-size in 'status' 48px, 'actions1' 50px, 'actions2' 50px, 'actions3' 65px, 'actions4' 86px, 'actions5' 107px, 'actions6' 128px, 'actions7' 149px, 'actions8' 170px
                &.#{$class}
                  max-inline-size: $inline-size
            &.check_table .tbody .tr
              box-shadow: none
              border-radius: 0px
              margin-block-end: 0px
              padding: 0px
              .td textarea
                border-radius: var(--button-radius)
            &.confirm_table
              background-color: transparent
              .thead .tr
                padding-inline: calc(2 * var(--padding))
                padding-block: 2px
              .tbody .rowgroup
                border-radius: var(--button-radius)
                .tr
                  margin: var(--padding)
                  &.new
                    background-color: lighten($orange, $step)

  /* front page */
  #front_page

    /* front page content section */
    #front_content
      inline-size: 100%
      h2
        display: none

      /* login modal */
      #login_modal
        position: fixed
        inset-inline-end: 0px
        inset-block-start: var(--front-banner-block-size)
        inline-size: 100%
        max-inline-size: 100%
        block-size: calc(100% - var(--front-banner-block-size))
        background: fade-out(black, 0.4)
        .login_dialog
          position: relative
          z-index: 3
          display: flex
          flex-flow: column
          margin: auto
          border-radius: 25px
          padding: calc(4 * var(--padding))
          inline-size: 70%
          block-size: 70%
          @media (max-width: 555px) and (max-height: 999px), (max-width: 999px) and (max-height: 555px)
            inline-size: 100%
            block-size: 100%
          min-inline-size: 320px
          max-inline-size: 800px
          min-block-size: 580px
          background: white
          color: $darkgray
          font-size: 16px
          button#close
            position: absolute
            inset-inline-end: calc(4 * var(--padding))
            inline-size: 15px
            line-height: 15px
            font-size: 25px
            text-decoration: none
            &:hover
              color: gold
          h2
            display: block
            margin-block-end: var(--padding)
            font-size: 24px
            color: black
          aside
            flex: auto
            display: flex
            flex-flow: column
            a, button.link
              color: gold
              &:hover
                color: black
            form
              flex: auto
              display: flex
              flex-flow: column
              justify-content: space-between
              align-items: flex-start
              .table
                inline-size: 80%
                min-inline-size: 280px
                color: gold
                .row .cell
                  line-height: 14px
                  + .cell
                    min-inline-size: 208px
                label
                  font-size: 14px
                input, textarea, select
                  inline-size: 100%
                textarea
                  block-size: 60px
              .buttons
                inline-size: 100%
                display: flex
                justify-content: space-between
                align-items: flex-end
          aside.login
            border-radius: 0px
            inline-size: auto
            padding: 0px
            background: transparent
            .links button
              display: block
              color: $gray
              &:hover
                color: black
            @media (max-width: 555px) and (max-height: 999px), (max-width: 999px) and (max-height: 555px)
              button#contact_us
                max-inline-size: 100px
        #astro
          display: none
          position: absolute
          inset-inline: 0px
          inset-block-end: 0px
          z-index: 3
          margin: auto
          inline-size: 23.7vh
          block-size: 25vh
          div
            position: absolute
            inset-inline-end: calc(50% + 13vh)
            border-radius: 20px
            border-end-end-radius: 0px
            inline-size: 180px
            padding: calc(2 * var(--padding))
            background: fade-out($brightwhite, 0.25)
            font-size: 16px
            text-align: center
            &::after
              content: ''
              position: absolute
              inset-inline-end: -20px
              inset-block-end: 0px
              inline-size: 20px
              block-size: 20px
              background: radial-gradient(circle at 20px 0px, transparent 0px 20px, fade-out($brightwhite, 0.25) 20px)
            @media (max-width: 555px) and (max-height: 999px), (max-width: 999px) and (max-height: 555px)
              inset-inline-end: calc(50% - 90px)
              inset-block-start: -22vh
              border-end-end-radius: 20px
              &::after
                inset-inline-end: calc(50% - 20px)
                inset-block-end: -20px
                inline-size: 40px
                background: radial-gradient(circle at 0px 20px, transparent 20px 0px, rgba(238, 238, 238, 0.75) 0px) 0px 0px / 20px no-repeat, radial-gradient(circle at 20px 20px, transparent 0px 20px, rgba(238, 238, 238, 0.75) 20px) 20px 0px / 20px no-repeat
          img
            block-size: 25vh
            margin: auto
            position: absolute
            inset-inline: 0px

      header#header
        position: fixed
        inset-block-start: 0px
        z-index: 2
        #banner
          box-shadow: none
          background: fade-out($outerspace, 0.5)
          color: white
          #sidebar_button
            display: none
          a
            color: white
            &:hover
              color: gold
          .announcement
            padding-inline: 0px
            background: transparent
            font-size: 24px
            color: white
          .button button
            box-shadow: 0px 2px 2px $deepgray

      main
        padding-inline: 0px
        padding-block-start: var(--front-banner-block-size)
        padding-block-end: 0px
        section#project_information
          button.button, a.button
            display: block
            margin-inline: auto
            margin-block: calc(3 * var(--padding))
            border: 1px outset gold
            border-radius: 25px
            padding-inline: calc(3 * var(--padding))
            padding-block: 7.5px
            background-color: gold
            color: black
            text-shadow: 1px 1px 2px white
            text-decoration: none
            font-size: 20px
            line-height: 26px
            &:hover
              border-color: grey
              background: darken(white, $half-step)
            &:active
              border: 1px inset grey
              background: white
            img
              display: inline
              margin-inline-end: var(--padding)
              block-size: 28px
              vertical-align: -6px

          footer
            padding: 0px
            .logos, .copyright
              padding: calc(2 * var(--padding))
              font-size: 12px
            .logos
              background-color: white
            .copyright
              background-color: $brightwhite

  /* regular pages */
  #page

    /* regular page sidebar with navigation and status info */
    aside#sidebar
      z-index: 2
      box-shadow: 0px 0 8px $darkgray
      text-transform: lowercase
      background: none
      &:first-child nav.menu ul li.current
        border-inline-start: 10px solid gold
      &:last-child nav.menu ul li.current
        border-inline-end: 10px solid gold
      h3, h4
        margin-block: 0px
        inline-size: auto
      h3
        padding: var(--padding)
        font-size: 16px
      h4
        padding-inline: calc(var(--padding) / 2)
        padding-block: calc(var(--padding) / 2)
        font-size: 14px
      nav.menu, aside
        padding: 0px
      nav.menu
        h3
          text-shadow: 1px 1px 2px $deepgray
        ul
          position: relative
          border: none
          li
            border: none
            &.current
              position: relative
              inset-inline-start: 0px
              box-shadow: 0px 2px 2px $lightgray
              border-block-start: 1px solid $lightgray
              background: white
              a
                background: transparent
                color: $deepgray
                text-shadow: 1px 1px 2px white
            a
              margin: 0px
              padding-inline: var(--padding)
              text-indent: 0px
              font-size: 13px
              &:hover
                background: lighten($brightwhite, $step)
                color: $deepgray
                text-shadow: 1px 1px 2px white
              img
                display: none
        &.student
          background: $brightwhite
          color: $deepgray
          h3
            text-shadow: 1px 1px 2px white
          ul li a
            color: $deepgray
            text-shadow: 1px 1px 2px white
        &.system, &.school
          background: $planet_fill
          color: white
          ul li:not(.current):not(:hover) a
            color: white
            text-shadow: 1px 1px 2px $deepgray
      aside
        &.info
          background: lighten($lightgray, $step)
          dl .row
            margin: 0px
            inline-size: 100%
            padding-inline: var(--padding)
            dt, dd
              font-size: 13px
          .scrollable .class
            .icons
              padding-inline: calc(var(--padding) / 2)
            a
              color: $deepgray
              &:hover
                color: gold
        &.copyright
          background: white
          h3
            font-size: 14px
          p
            padding-inline: var(--padding)
            font-size: 12px

    /* regular page content */
    #content

      /* breadcrumbs - navigating backwards within the hierarchy */
      nav#breadcrumbs
        inline-size: 100%
        background: lighten($lightgray, $step)
        text-transform: lowercase
        font-size: 13px
        ul li a
          color: black
          &:hover
            color: gold

      /* main content */
      main
        .accordion
          border: none
          border-radius: var(--button-radius)
          padding-block-end: 3px
          background: transparent
          .accordion
            margin-block-start: var(--padding)
          h4, h5, h6
            box-shadow: 0px 2px 2px $gray
            border-radius: var(--button-radius)
          article + :is(h4, h5)
            margin-block-start: var(--padding)
          h4
            background: $planet_fill
            font-size: 16px
            &:hover
              background: darken($planet_fill, $half-step)
          h5, h6
            background: lighten($lightgray, $half-step)
            font-size: 14px
            &:hover
              background: $lightgray
        article.run
          padding-inline: 0px
          padding-block: 0px 3px
          background: transparent
        article.item
          margin-inline: calc(var(--padding) / 2)
          margin-block: var(--padding) 0px
          h4, h5, h6
            font-size: 14px
            line-height: 18px
            background: lighten($lightgray, $half-step)
            &:hover
              background: lighten($lightgray, $half-step)
          .content
            .info
              font-size: 14px
            .status :is(.message, .cell.type)
              font-size: 14px
          section.buttons .button a
            font-size: 13px


        /* for Vue pages */
        iframe
          border: none
          background: transparent

        /*
         * CONTAINER STYLES
         */
        /* panel view setting pages */
        &.panel_view
          section.panel
            margin-inline: 0px
            padding: 0px
            background: none
            &#project_information
              color: $deepgray
              a.button
                display: none
            p, ul, ol
              font-size: 13px
            @media (max-width: 555px) and (max-height: 999px), (max-width: 999px) and (max-height: 555px)
              .table
                inline-size: 100%

        /* tab view for edit pages */
        &.tab_view
          position: relative
          ul.tabs
            z-index: 2
            justify-content: center
            li
              font-size: 14px
              line-height: 23px
              text-transform: lowercase
              &.disabled, a
                box-shadow: 0px 2px 2px $darkgray
                border-block-end: 1px solid $darkgray
                border-radius: 0px
                padding-inline: calc(2 * var(--button-padding))
                padding-block: calc(var(--button-padding) / 2)
                background: darken($lightgray, $step)
                color: white
                text-align: center
                text-shadow: 1px 1px 2px black
              img
                display: none
              &:first-child a, &:first-child.disabled
                border-radius: var(--button-radius) 0px 0px var(--button-radius)
              &:last-child a, &:last-child.disabled
                border-radius: 0px var(--button-radius) var(--button-radius) 0px
              &:first-child:last-child a, &:first-child:last-child.disabled
                border-radius: var(--button-radius)
              &:hover a
                background: $lightgray
              &.ui-tabs-active a
                border-block-end: 1px solid $lightgray
                background: lighten($lightgray, $step)
                color: black
                text-shadow: 1px 1px 2px white
          .panels
            border: 2px solid $outerspace
            border-radius: var(--button-radius)
            margin-block-start: calc(1px - var(--button-radius))
            padding-block-start: calc(var(--padding) + var(--button-radius))
            background: none
            section.content section.list
              border: none
              > .table :is(.thead, .tfoot) .tr
                background: $planet_fill
                color: white

        /* student pages */
        &.student
          background: url("planets/stars.png") center top / auto 100%
          h2
            font-size: 20px
            color: gold
            text-shadow: 1px 1px 2px black
          section.panel
            background: transparent
            font-size: 0px
            color: white
            h4
              text-shadow: 1px 1px 2px black
            h5
              color: black
            .table .row .cell
              color: white
            a, button.link
              color: white
              &:hover
                color: gold
          section.list > .table .tbody
            background: transparent
          .button a, .button button, a.button, button.button, input[type="submit"], .button button[type="submit"]
            box-shadow: 0px 2px 2px black

          &.dash_view
            p
              margin: var(--padding)
              inline-size: 70%
              @media (max-width: 555px) and (max-height: 999px), (max-width: 999px) and (max-height: 555px)
                inline-size: calc(100% - 2 * var(--padding))
              font-size: 16px
              color: white
            article.run
              .message
                color: gold
                font-size: 24px
                font-weight: normal
              article.item
                border: 2px solid gold
                background: transparent
                .info
                  font-size: 12px
                  color: gold
                .buttons .button button
                  background: gold

          &.tab_view
            ul.tabs li
              &.disabled, a
                box-shadow: 0px 2px 2px black
            .panels
              border-color: gold
