// Tutorshop & Mathtutor styles

@import mixins
@import gradients
@import colors

@import buttons
@import dialogs

@import tutorshop
@import mathtutor
@import planets
@import phzh

@import android
@import ipados_ios
@import firefox
@import safari

/*
 * GENERAL DEFAULTS
 */
\:root
  --gap: var(--padding)
  --padding: 10px
  --min-inline-size: 340px
  --min-block-size: 500px
  --banner-block-size: calc(30px + 2 * var(--padding))
  --front-banner-block-size: calc(45px + 2 * var(--padding))
  --min-sidebar-inline-size: 145px
  --max-sidebar-inline-size: 200px
  --login-inline-size: 300px
  --font-family-web: Verdana, Geneva, 'Lucida Sans Unicode', 'Lucida Grande', 'DejaVu Sans', Helvetica, Arial, sans-serif
  --font-family-code: Monaco, 'DejaVu Sans Mono', 'Courier New', monospace
  --font-family-native: system-ui, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Arial, "Noto Sans", sans-serif
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  @media (max-width: 555px) and (max-height: 999px), (max-width: 999px) and (max-height: 555px)
    --padding: 7px
    --min-inline-size: 320px
    --min-block-size: 300px

*, *:before, *:after
  margin: 0px
  box-sizing: inherit

*:focus-within
  z-index: 1

html
  box-sizing: border-box
  min-inline-size: var(--min-inline-size)
  min-block-size: var(--min-block-size)
  block-size: 100%
  hyphens: auto

body
  block-size: 100%
  overflow-y: hidden
  padding: 0px
  display: flex
  flex-flow: column
  background: url("background/background.png") white repeat
  font-family: var(--font-family-web)
  font-size: 0px

table
  border: none
  border-spacing: 0px

ul, ol
  padding: 0px

meter
  border: 1px solid $lightgray
  border-radius: 20px
  background: $lightgray lighten-gradient($lightgray) no-repeat
  &::-webkit-meter-bar
    border-radius: 20px
    background: $lightgray lighten-gradient($lightgray) no-repeat
  &::-webkit-meter-suboptimum-value
    border-radius: 20px
    background: darken($yellow, $half-step) lighten-gradient(darken($yellow, $half-step)) no-repeat
  &::-webkit-meter-optimum-value
    border-radius: 20px
    background: $green lighten-gradient($green) no-repeat

progress
  block-size: 28px
  flex: 1

div.field_with_errors
  box-shadow: 0px 0px 1.5px 1px red
  inline-size: 100%

/*
 * TEXT STYLES
 */
p
  line-height: 125%

h2, h3, h4
  hyphens: none

h2
  font-weight: normal
  font-size: 18px

h3
  margin-block: calc(var(--padding) + 6px)
  font-size: 16px
  font-weight: bold

h4
  margin-block: calc(var(--padding) + 3px)
  font-size: 14px
  font-weight: bold

h5
  margin-block: var(--padding)
  font-size: 12px
  font-weight: bold

pre, code, blockquote
  margin: var(--padding)
  padding: var(--padding)
  white-space: pre-wrap
  overflow-wrap: anywhere
  background-color: $lightgray
  font-family: var(--font-family-code)

blockquote
  font-style: italic

/*
 * LINKS & BUTTONS
 */
a, button.link
  display: inline-block
  border-radius: var(--button-radius)
  text-align: left
  text-decoration: underline
  color: darken($darkgreen, $step)
  font-size: inherit
  &:hover
    color: darken($firebrick, $step)
  img
    border: none

/*
 * FORM FIELDS
 */
input, textarea, select, label, legend, a
  font-family: inherit
  font-size: 12px

input, select
  color: black

textarea, label, legend
  line-height: 13px

\:is(input:not([type="submit"]), textarea, select):disabled
  color: $darkgray

input[type="text"], input[type="password"], input[type="email"], input[type="search"], input[type="number"], input[type="url"], input[type="date"]
  padding: 2px

input[type="number"]
  padding-right: 0px

input[type="file"]::file-selector-button
  border: 1px solid $darkgray
  border-radius: 2px
  padding-block: 3px
  background-color: white

input[type="checkbox"], input[type="radio"]
  vertical-align: -2px

textarea
  resize: none
  padding-inline: 3px
  padding-block: 0px

select
  border-radius: 2px
  padding-block: 2px
  background-color: white

label
  padding-inline: 0px
  padding-block: 2px 0px
  overflow-wrap: anywhere

/*
 * COMMON ELEMENTS
 */
/* inline images */
img.inline_icon
  vertical-align: -3px
  background-color: transparent

/* hide something */
.hidden
  visibility: hidden !important

.hide, .filtered
  display: none !important

.disabled
  opacity: 0.5

.ui-sortable-handle
  cursor: move

.visually_hidden
  @include reader-visible

/*
 * LAYOUT STYLES
 */
/* main container for all pages */
#front_page, #page
  display: flex
  block-size: 100%
  .scrollable
    overflow-x: hidden
    overflow-y: auto
  .table
    display: table
    border-collapse: collapse
    margin-inline: auto
    label
      hyphens: none
      input[type="radio"], span
        vertical-align: middle
    .row
      display: table-row
      .cell
        display: table-cell
        vertical-align: middle
        padding-block: 3px
        font-size: 12px
        &:not(:first-child)
          padding-inline-start: var(--padding)
        @each $selector, $inline-size in 'input[type="text"]' 100%, 'input[type="password"]' 100%, 'input[type="date"]' 100%, 'input[type="email"]' 100%, 'input[type="url"]' 100%, 'input[type="tel"]' 100%, 'input[type="number"]' 100%, 'input[type="file"]' 100%
          #{$selector}
            box-sizing: border-box
            inline-size: $inline-size
      &.spacer .cell
        padding-block-start: 8px
  .ui-sortable-placeholder
    margin-inline: var(--gap)

  /* sidebars */
  aside#front_sidebar, aside#sidebar
    contain: content
    flex: 1
    min-inline-size: var(--min-sidebar-inline-size)
    max-inline-size: var(--max-sidebar-inline-size)
    overflow-y: auto
    background: url("background/background.png") white repeat
    &:first-child
      margin-inline-start: var(--gap)
    &:last-child
      margin-inline-end: var(--gap)
    @media (max-width: 555px) and (max-height: 999px), (max-width: 999px) and (max-height: 555px)
      display: none
      position: absolute
      box-shadow: 2px 0 4px $gray
      inline-size: var(--min-sidebar-inline-size)
      block-size: 100%
      z-index: 10
      &:first-child
        left: 0px
        margin-inline: 0px
      &:last-child
        right: 0px
        margin-inline: 0px
    > aside, > nav, > a aside
      contain: content
      position: relative
      padding: var(--padding)
      background: url("background/background.png") white repeat
    \:is(.students, .instructors, .menu, .info) + :is(nav, aside), :is(nav, aside) + :is(.students, .instructors, .menu, .info)
      margin-block-start: var(--gap)
    h3
      margin-block: 0px var(--padding)
      inline-size: 100%
      font-size: 13px
    h4
      margin-block: 0px var(--padding)
      font-size: 12px
    aside.copyright
      h3
        font-size: 12px
        font-weight: normal
      p
        font-size: 11px
        color: $deepgray
    p + p
      margin-block-start: 8px

  /* content sections */
  #front_content, #content
    // contain: content
    position: relative
    flex: 4
    min-inline-size: var(--min-inline-size)
    margin-inline: var(--gap)
    display: flex
    flex-flow: column
    background: url("background/background.png") white repeat
    @media (max-width: 555px) and (max-height: 999px), (max-width: 999px) and (max-height: 555px)
      margin-inline: 0px
    /* general header - the site title, welcome message and login/logout button - occasionally also test bar and messages */
    header#header
      flex: none
      background: url("background/background.png") white repeat
      #tests .message_warning
        padding: 5px
        background: lighten($orange, $half-step)
        text-align: center
        font-size: 12px
      aside#messages
        position: absolute
        inset-inline: 0px
        inset-block-start: 0px
        z-index: 2
        margin-inline: auto
        inline-size: calc(50% + 200px)
        max-inline-size: calc(100% - 100px)
        text-align: center
        font-size: 13px
        > div
          margin-block-start: var(--padding)
          border-radius: var(--button-radius)
          padding: var(--padding)
          overflow-wrap: break-word
        @each $selector, $text_color, $color in '#flashnotice' white $darkgreen, '#flashalert' white $firebrick
          #{$selector}
            display: none
            color: $text_color
            background: lighten($color, $half-step)
      #banner
        contain: strict
        block-size: var(--banner-block-size)
        padding-inline: var(--padding)
        background: lighten($green, $half-step)
        display: flex
        justify-content: flex-end
        align-items: center
        gap: var(--padding)
        hyphens: none
        .button
          flex: none
        #sidebar_button button
          @media (max-width: 555px) and (max-height: 999px), (max-width: 999px) and (max-height: 555px)
            font-size: 0px
          span
            font-size: 17px
            line-height: 12px
            @media (max-width: 555px) and (max-height: 999px), (max-width: 999px) and (max-height: 555px)
              vertical-align: -6px
        > a
          max-block-size: 100%
          img
            inline-size: 100%
            max-block-size: var(--banner-block-size)
        .middle
          flex: auto
          block-size: 100%
          padding-block: calc(var(--padding) / 2)
          display: inline-flex
          justify-content: flex-end
          align-items: center
          font-size: 12px
          line-height: 12px
        @each $class, $color in 'system' $firebrick, 'school' darken($blue, $step), 'student' $darkgreen, 'independent_student' $darkgreen
          &.#{$class} .button a, &.#{$class} .button button
            border-color: lighten($color, $half-step)
            background: $color darken-gradient(lighten($color, $half-step))
            color: white
            text-shadow: 1px 1px 2px $deepgray
            &:hover
              border-color: darken($color, $half-step)
              background: darken($color, $step) darken-gradient(darken($color, $half-step))
            &:active
              border-color: darken($color, $half-step)
              background: $color darken-gradient(lighten($color, $half-step), $step, 'to top')
    &:first-child #banner .button:first-child
      order: 4
    section.panel .table .row .cell
      &:first-child
        inline-size: 30%
      &:last-child
        inline-size: 70%

    /* main content */
    main
      // contain: content
      flex: 1 0 auto
      padding: var(--padding)
      background: $lightblue
      display: flex
      flex-flow: column
      gap: var(--padding)
      h2
        overflow-wrap: break-word

      /* panels */
      section.panel, section.list
        border: 1px solid $darkblue
        overflow-y: auto
        flex: 1 0 0px
        display: flex
        flex-flow: column
      .message
        margin: 15px
        font-size: 16px
        font-weight: bold
        color: darken($firebrick, $step)

      /* input forms for settings pages */
      form
        flex: 1 0 auto
        display: flex
        flex-flow: column
        gap: var(--padding)
        section.panel
          font-size: 0px
          p
            font-size: 12px
          .table
            inline-size: 80%
            @media (max-width: 555px) and (max-height: 999px)
              inline-size: 100%
          /* fields on settings pages */
          \:is(input, textarea, select):invalid
            box-shadow: 0px 0px 1.5px 1px red
          label.status
            display: inline-block
            margin-inline-end: var(--padding)
            padding: 2px
          textarea
            block-size: 47px
          .logging
            margin-inline: auto
            display: flex
            gap: var(--padding)
          input[type="checkbox"]
            margin-block-start: 4px
            margin-inline-end: 5px
          input[type="radio"]
            margin-inline-end: 5px
          .cell
            @each $selector, $inline-size in 'input#school_city' 50%, 'input#school_state' 20%, 'input#school_postal_code' 30%, 'input#user_first_name' 90%, 'input#user_middle_name' 10%, 'input#student_first_name' 90%, 'input#student_middle_name' 10%, 'textarea' 100%, 'select' 100%, 'select.date' 33%, 'select.date + select.date' 40%, 'select.date + select.date + select.date' 27%, 'input#school_consumer_key' 50%, 'input#school_provider_key' 50%, 'input#school_consumer_secret' 50%, 'input#school_provider_secret' 50%
              #{$selector}
                box-sizing: border-box
                inline-size: $inline-size
            @each $class, $inline-size in 'skill_param' 25%, 'problem_set_param' 33.3%
              label.#{$class}
                display: inline-block
                inline-size: $inline-size
                text-align: center
              input.#{$class}
                box-sizing: border-box
                inline-size: $inline-size
            label[for="school_autogenerate_consumer"], label[for="school_autogenerate_provider"]
              display: inline-block
              inline-size: 50%
              text-align: center
            input#school_autogenerate_consumer, input#school_autogenerate_provider
              margin-inline-end: 0px
            .check
              display: inline-block
              inline-size: 50%
              text-align: center

        /* contact us pages */
        section#interest_attributes textarea
          min-block-size: 100px
        section#comment_attributes textarea
          min-block-size: 150px

      /* buttons row on bottom */
      section.buttons
        display: flex
        justify-content: flex-end
        gap: var(--padding)
        input[type="file"]
          display: none
        .left, .right
          display: flex
          flex-flow: wrap
          align-content: flex-start
          gap: var(--padding)
        .left
          margin-inline-end: auto
          justify-content: flex-start
        .right
          justify-content: flex-end

      /* tutor pictures with buttons */
      .tutors
        margin-block-end: var(--padding)
        .images .image :is(button.run, button.previous, button.next, .indices button.index)
          border: 1px outset lighten($lightgray, $step)
          box-shadow: 0px 3px 3px $darkgray
          cursor: pointer
          &:hover
            border-color: $lightgray
          &:active
            border: 1px inset $lightgray
        .images
          overflow: hidden
          .image
            position: relative
            margin-inline: auto
            max-inline-size: 800px
            min-block-size: 150px
            img
              inline-size: 100%
            button.run
              position: absolute
              inset-inline: 0px
              inset-block: 0px
              margin: auto
              border-radius: var(--button-radius)
              inline-size: 66px
              block-size: 56px
              @media (max-width: 555px) and (max-height: 999px)
                inline-size: 44px
                block-size: 38px
              background: url("tutors/play_button.png") center no-repeat, lighten($lightgray, $half-step) lighten-gradient($lightgray)
              &:hover
                background: url("tutors/play_button.png") center no-repeat, darken($lightgray, $half-step) darken-gradient($lightgray)
              &:active
                background: url("tutors/play_button.png") center no-repeat, lighten($lightgray, $half-step) lighten-gradient($lightgray, $step, 'to top')
              &, &:hover, &:active
                @media (max-width: 555px) and (max-height: 999px)
                  background-size: 22px
            button.previous
              inset-inline-start: 0px
              border-radius: 0px var(--button-radius) var(--button-radius) 0px
              background: url("tutors/arrow_left.png") center no-repeat, lighten($lightgray, $half-step) lighten-gradient($lightgray)
              &:hover
                background: url("tutors/arrow_left.png") center no-repeat, darken($lightgray, $half-step) darken-gradient($lightgray)
              &:active
                background: url("tutors/arrow_left.png") center no-repeat, lighten($lightgray, $half-step) lighten-gradient($lightgray, $step, 'to top')
            button.next
              inset-inline-end: 0px
              border-radius: var(--button-radius) 0px 0px var(--button-radius)
              background: url("tutors/arrow_right.png") center no-repeat, lighten($lightgray, $half-step) lighten-gradient($lightgray)
              &:hover
                background: url("tutors/arrow_right.png") center no-repeat, darken($lightgray, $half-step) darken-gradient($lightgray)
              &:active
                background: url("tutors/arrow_right.png") center no-repeat, lighten($lightgray, $half-step) lighten-gradient($lightgray, $step, 'to top')
            button.previous, button.next
              position: absolute
              inset-block: 0px
              margin-block: auto
              inline-size: 33px
              block-size: 56px
              @media (max-width: 555px) and (max-height: 999px)
                inline-size: 22px
                block-size: 38px
              &, &:hover, &:active
                @media (max-width: 555px) and (max-height: 999px)
                  background-size: 22px
            .indices
              position: absolute
              inset-inline: 0px
              inset-block-end: 10px
              text-align: center
              button.index
                margin-inline-start: 5px
                border-radius: calc(2 * var(--button-radius))
                inline-size: 15px
                block-size: 15px
                @media (max-width: 555px) and (max-height: 999px)
                  margin-inline-start: 3px
                  inline-size: 10px
                  block-size: 10px
                background: lighten($lightgray, $half-step) lighten-gradient($lightgray)
                &.current
                  background: lighten($dot, $half-step) lighten-gradient($dot, $step, 'to top')
                &:hover
                  background: darken($lightgray, $half-step) darken-gradient($lightgray)
                &:active
                  background: lighten($lightgray, $half-step) lighten-gradient($lightgray, $step, 'to top')

      /* project presentation for front & about pages */
      section#project_information
        flex: 1 1 0px
        ul
          margin-inline: 16px 0px
          margin-block: 0px 8px
          li
            margin-inline: var(--padding)
        .columns
          display: flex
          justify-content: space-between
          gap: var(--padding)
          .column
            flex: 1 1 0px
        .alternate .icon
          display: flex
          align-items: center
          img
            min-block-size: 30px
          span
            flex: auto
            padding-inline: var(--padding)
          .button
            margin: 0px
            flex: none

      /* panel view setting pages */
      &.panel_view
        section.panel
          border: 1px outset lighten($lightgray, $step)
          background: lighten($lightgray, $step)
          padding: var(--padding)
          font-size: 12px
          .scrollable p
            margin-block: 8px
        ul, ol
          margin-inline: 16px

/* front page */
#front_page
  /* front page sidebar */
  aside#front_sidebar
    aside
      p
        font-size: 12px
      &.students, &.instructors
        background: $lightblue
        p, span
          margin-block-end: 0px
          font-size: 13px
      &.testimonials p.right
        inline-size: 80%
      .right
        align-self: flex-end
    > a
      display: block
      text-decoration: none
      aside
        box-shadow: 0px 2px 4px $darkgray
        border: 2px outset
        color: black
        text-shadow: 1px 1px 2px white
        &:active
          box-shadow: 0px 1px 2px $darkgray
          border: 2px inset
          padding-inline: 11px 9px
          padding-block: 11px 9px
        &.students, &.instructors
          border-color: lighten($lightblue, $half-step)
          background: $lightblue darken-gradient(lighten($lightblue, $half-step))
          &:hover
            border-color: darken($lightblue, $half-step)
            background: darken($lightblue, $step) darken-gradient(darken($lightblue, $half-step))
          &:active
            border-color: darken($lightblue, $half-step)
            background: $lightblue darken-gradient(lighten($lightblue, $half-step), $step, 'to top')

  /* front page content section */
  #front_content
    overflow-y: auto
    .g-recaptcha
      inline-size: 100%
      // transform: scale(86%)
      // transform-origin: 0px 0px
      > div, iframe
        inline-size: 100% !important
        max-inline-size: 304px
        max-block-size: 78px

    /* login modal */
    #login_modal
      display: none
      position: absolute
      inset-inline-end: 0px
      @media (max-width: 555px) and (max-height: 999px), (max-width: 999px) and (max-height: 555px)
        inset-inline-end: 0px
      z-index: 2
      inline-size: var(--login-inline-size)
      .login_dialog
        border-radius: var(--button-radius)
        padding: var(--padding)
        background: darken($firebrick, $half-step)
        h2
          min-block-size: initial
          color: white
          font-size: 14px
          font-weight: bold
        form
          .table
            margin-block-end: var(--padding)
            inline-size: 80%
            color: white
            label
              font-size: 13px
          section.buttons
            display: flex
            justify-content: center
            gap: var(--padding)

    /* front page header */
    header#header #banner
      block-size: var(--front-banner-block-size)
      padding-inline: calc(var(--padding) - var(--gap))
      @media (max-width: 555px) and (max-height: 999px), (max-width: 999px) and (max-height: 555px)
        padding-inline: var(--padding)
      background: none transparent
      > a img
        max-block-size: var(--front-banner-block-size)
      .announcement
        flex: 1 auto
        display: none
        border-radius: var(--button-radius)
        padding-inline: var(--padding)
        background: lighten($firebrick, $half-step)
        text-align: center
        color: white
        font-size: 16px
        line-height: 100%
      .right
        flex: none

    /* main section of front page */
    main
      h2
        margin-inline: var(--padding)
        font-weight: bold
      section#project_information p
        font-size: 14px
    footer
      flex: none
      padding: var(--padding)
      background: url("background/background.png") white repeat
      &#sponsors, &#sponsors_copyright
        p
          margin-block-end: var(--padding)
          text-align: center
          font-size: 12px
        .logos
          display: flex
          flex-flow: wrap
          justify-content: space-around
          align-items: center
          gap: var(--padding)
          a
            max-inline-size: 40%
            img
              inline-size: 100%
              vertical-align: middle

/* regular pages */
#page

  /* regular page sidebar with navigation and status info */
  aside#sidebar
    nav.menu
      color: white
      ul
        border-block-start: 1px solid white
        border-block-end: 1px solid $lightgray
        display: flex
        flex-flow: row wrap
        justify-content: flex-start
      li
        display: inline
        flex: 0 auto
        &:last-child
          flex: 1 0 auto
        inline-size: 100%
        border-block-start: 1px solid $lightgray
        border-block-end: 1px solid white
        a
          display: block
          border-radius: 0px
          padding-block: 5px
          font-size: 12px
          color: white
          text-decoration: none
          text-shadow: 1px 1px 2px $deepgray
          img
            margin-inline-end: 5px
            block-size: 16px
        &.current a
          font-size: 13px
      @each $class, $color in 'system' $firebrick, 'school' darken($blue, $step), 'student' $darkgreen, 'independent_student' $darkgreen
        &.#{$class}
          background: darken($color, $half-step)
          li a:hover, li.current a
            background: lighten($color, $step)
    aside.info
      &.help
        display: none
      background: $lightblue
      dl
        display: flex
        flex-flow: row wrap
        .row
          inline-size: 100%
          padding-inline: 0px
          padding-block: 3px
          dt, dd
            display: inline-block
            font-size: 12px
          dt
            inline-size: 70%
          dd
            inline-size: 30%
            text-align: right
      .scrollable
        min-block-size: 56px
        background-color: white
        .class
          padding: 5px
          @each $class, $color in 'row_odd' white, 'row_even' lighten($lightgray, $half-step)
            &.#{$class}
              background-color: $color
          a
            color: darken($firebrick, $step)
            &:hover
              color: $firebrick

  /* regular page content */
  #content
    main + header, header + main
      margin-block-start: var(--gap)

    /* breadcrumbs - navigating backwards within the hierarchy */
    nav#breadcrumbs
      flex: none
      padding: var(--padding)
      background: url("background/background.png") white repeat
      font-size: 12px
      ul li
        display: inline
        a
          text-shadow: 1px 1px 2px white

    /* main content */
    main
      &.dashboard.planets
        background-color: $outerspace
        > header
          h2
            color: gold
          .navigation a
            color: gold
      > header
        display: flex
        justify-content: space-between
        .navigation
          margin-inline: var(--padding)
          margin-block-start: var(--padding)
          a
            text-align: center
            text-decoration: none
            &:hover
              color: white
            img
              margin-inline: auto
              display: block
              block-size: 30px
            span
              font-size: 12px

      /* side by side lists */
      .sides
        display: flex
        gap: var(--padding)
      .full
        inline-size: 100%
        flex: 1 0 100px
        display: flex
        flex-flow: column
        gap: var(--padding)
      .side
        flex: 1 1 0px
        min-inline-size: 0px
        display: flex
        flex-flow: column
        gap: var(--padding)
        &:first-child > *
          margin-inline-end: 0px
        &:last-child > *
          margin-inline-start: 0px
      .split
        block-size: 100%
        display: flex
        flex-flow: column
        gap: var(--padding)
        @media (min-width: 820px)
          flex-flow: row
        .third > *
          @media (min-width: 820px)
            margin-inline-end: 0px
        .center > *
          @media (min-width: 820px)
            margin-inline-start: 0px

      /* scrollable content */
      .third
        flex: 1 0 100px
        overflow-x: hidden
      .center
        flex: 2 0 200px
      .scrollable
        flex: 1 0 0px
        display: flex
        flex-flow: column

      /* top filters */
      form
        section.filters
          display: flex
          align-items: center
          gap: var(--padding)
          \:not(button)
            flex: 1
            min-inline-size: 0px
          label
            flex: none
          button[type="submit"]
            display: none

      /* container for tables */
      section.list
        flex: auto
        display: flex
        flex-flow: column
        > .table
          inline-size: 100%
          flex: 1 0 0px
          display: flex
          flex-flow: column
          overflow-wrap: anywhere
          overflow-y: auto
          \:is(.thead, .tfoot) .tr
            padding-block: 2px
            background: lighten($darkblue, $half-step) lighten-gradient($darkblue)
            color: white
            text-align: left
            text-shadow: 1px 1px 2px black
          .tbody
            position: relative
            flex: auto
            outline-offset: -3px
            overflow-y: auto
            background-color: white
            .rowgroup[data-sortables-target]
              border: 2px solid $darkblue
              &:nth-child(n + 2)
                margin-block-start: -2px
            .tr.sortable
              cursor: move
              @each $class, $color in 'row_odd' lighten($lightblue, $step), 'row_even' lighten($lightblue, $half-step)
                &.#{$class}:hover, &.#{$class}.ui-sortable-helper
                  background-color: $color
              &.ui-sortable-helper
                border: 0px none
            .tr.dummy
              visibility: hidden
          .tr
            // content-visibility: auto
            padding-inline: calc(var(--padding) / 2)
            display: flex
            align-items: center
            gap: calc(var(--padding) / 2)
          @each $class, $color in 'row_odd' white, 'row_even' lighten($lightgray, $step), 'row_odd_inner' white, 'row_even_inner' lighten($lightgray, $step), 'selected.row_odd' lighten($lightblue, $step), 'selected.row_even' lighten($lightblue, $half-step)
            .tr.#{$class}
              background-color: $color
          .td, .th
            display: inline-block
            padding-block: 4px
            font-size: 12px
            flex: 1
            img.inline_icon
              vertical-align: -4px
            img.inline_icon, a, button
              margin-inline-end: 3px
            img.inline_icon:last-child, a:last-child, button:last-child
              margin-inline-end: 0px
            a, button
              max-inline-size: 100%
            &.message
              margin-inline: 0px
              font-size: 11px
            &.icon, &.checkbox
              text-align: center
            &.arrows
              font-size: 16px
            &.binary
              font-size: 8px
            &.count, &.percent, &.index
              text-align: center
            @each $class, $flex in 'name' 6, 'address' 8, 'city' 4, 'state' 2, 'code' 3, 'country' 3, 'label' 6, 'fullname' 6, 'grade' 4, 'schoolyear' 4, 'access' 3, 'username' 5, 'password' 3, 'progress' 10, 'type' 2, 'percent' 2, 'binary' 6, 'date' 4, 'path' 8, 'sequence' 4, 'count' 4, 'index' 3, 'file' 6, 'description' 9, 'examples' 6, 'score' 2, 'container' 8, 'time' 3, 'week' 8, 'completed' 4, 'activity' 4, 'tree' 9, 'level' 3, 'status' 3, 'loaded' 2, 'actions1' 2, 'actions2' 2, 'actions3' 3, 'actions4' 4, 'actions5' 5, 'actions6' 6, 'actions7' 7, 'actions8' 8, 'actions9' 9
              &.#{$class}
                flex: $flex $flex 0px
            @each $class, $inline-size in 'hidden' 0, 'arrows' 12px, 'icon' 39px
              &.#{$class}
                flex: none
                inline-size: $inline-size
            @each $class, $inline-size in 'status' 50px, 'actions1' 50px, 'actions2' 50px, 'actions3' 62px, 'actions4' 81px, 'actions5' 100px, 'actions6' 119px, 'actions7' 138px, 'actions8' 157px, 'actions9' 176px
              &.#{$class}
                max-inline-size: $inline-size
            &.container meter
              display: block
              margin-block: 2px
              inline-size: 100%
              block-size: 10px
              padding: 0px
              &::-webkit-meter-bar
                block-size: 10px
          .th, .tfoot .td
            font-weight: bold
          .tfoot .td
            padding-block: 2px
            &.count:last-child
              text-align: right
            .table
              margin-inline-end: 0px
              overflow-wrap: initial
              .cell
                img
                  margin-inline-end: 5px
                  vertical-align: middle
                span
                  display: inline-block
                  inline-size: calc(100% - 21px)
                  vertical-align: middle
          &.check_table
            flex: auto
            display: flex
            flex-flow: column
            .tbody
              display: flex
              flex-flow: column
              .tr
                padding: 2px
                flex: auto
                flex-flow: column
                .td
                  inline-size: 100%
                  flex: auto
                  display: flex
                  padding: 0px
                  font-size: 0px
                  textarea
                    inline-size: 100%
                    border: none
                    overflow-x: auto
                    overflow-y: auto
                    white-space: pre
                    &.oks
                      color: $darkgreen
                    &.errors
                      color: lighten($firebrick, $step)
          &.confirm_table
            display: flex
            flex-flow: column
            .tbody .rowgroup
              border: 1px solid $deepgray
              .tr.new
                background-color: lighten($darkgreen, $step)
          &.tree
            .scrollable
              min-inline-size: 100%
              .link
                min-inline-size: 50px
            .thead .tr .th button
              color: white
              font-weight: bold
            .tbody .tr .td, .thead .tr .th
              &.file, &.tree
                flex-basis: 70px
                display: list-item
                counter-increment: list-item 0
                list-style: inside disclosure-closed
                padding-inline-start: calc(16px * var(--depth))
                &[open]
                  list-style: inside disclosure-open
                &.notree
                  list-style: none
                &::marker
                  font-size: 14px
                  line-height: 14px
                &.empty::marker
                  color: transparent
                .folder, .directory
                  inline-size: calc(100% - 16px)
                  vertical-align: text-top
                  display: inline-flex
                  align-items: flex-start
                  a
                    inline-size: calc(100% - 16px)
        &#class_table > .table .tfoot .th.name
          flex: 18 18
          min-inline-size: 192px
      .accordion
        flex: 1 0 auto
        display: flex
        flex-flow: column
        background-color: white
        h4, h5
          flex: none
          display: list-item
          counter-increment: list-item 0
          list-style: inside disclosure-closed
          margin-block: 0px
          outline-offset: -2px
          text-indent: calc(-2 * var(--button-padding))
          cursor: pointer
          font-weight: bold
          &.ui-accordion-header-active
            list-style: inside disclosure-open
          &::marker
            font-size: 14px
            line-height: 14px
        h4
          padding-inline: calc(4 * var(--button-padding))
          padding-block: var(--button-padding)
          font-size: 14px
          color: white
          text-shadow: 1px 1px 2px black
          background: lighten($darkblue, $half-step) lighten-gradient($darkblue)
          &:hover
            background: lighten($darkblue, $step) lighten-gradient(lighten($darkblue, $half-step))
        h5, h6
          padding-inline: calc(7 * var(--button-padding))
          padding-block: var(--button-padding)
          font-size: 13px
          text-shadow: 1px 1px 2px white
          background: darken($lightgray, $half-step) darken-gradient($lightgray)
          &:hover
            background: $lightgray darken-gradient(lighten($lightgray, $half-step))
      article.run
        flex: 1 0 auto
        min-block-size: 52px
        padding: calc(var(--padding) / 2)
        background: white
        .message
          margin: 15px
          font-size: 16px
          color: darken($firebrick, $step)
      article.item
        contain: content
        display: flex
        flex-flow: column
        margin: calc(var(--padding) / 2)
        border-radius: var(--button-radius)
        box-shadow: 0px 2px 2px $gray
        min-block-size: 100px
        padding: var(--padding)
        gap: var(--padding)
        background-color: lighten($lightgray, $step)
        &:hover
          transform: scale(1.01)
          box-shadow: 0px 4px 4px $gray
        &:not(:first-child)
          margin-block-start: var(--padding)
        h4, h5, h6
          display: block
          padding-inline: calc(2 * var(--button-padding))
          background: darken($lightgray, $half-step) darken-gradient($lightgray)
          text-indent: 0px
          font-size: 13px
          color: black
          text-shadow: 1px 1px 2px white
          cursor: unset
          &:hover
            background: darken($lightgray, $half-step) darken-gradient($lightgray)
        .content
          flex: auto
          display: flex
          justify-content: space-between
          gap: var(--padding)
          .presentation
            min-inline-size: 135px
            max-inline-size: calc(50% - var(--padding) / 2)
            display: flex
            flex-flow: column
            justify-content: center
            align-items: center
            .image
              flex: none
              border: 1px solid $darkgray
              block-size: 82px
              overflow: hidden
              img
                block-size: 100%
          @media (max-width: 555px) and (max-height: 999px), (max-width: 999px) and (max-height: 555px)
            .presentation
              min-inline-size: 100px
          .info
            flex: 1
            display: flex
            flex-flow: column
            gap: var(--padding)
            font-size: 12px
            .label
              font-size: 14px
            .description
              flex: auto
              inline-size: 100%
          .status
            flex: 1
            display: flex
            gap: var(--padding)
            &.bars
              flex-flow: column
            .group
              flex: 1
            .message
              margin-inline: 0px
              margin-block: 0px 5px
              font-size: 12px
            .row
              display: flex
              .cell.type
                min-inline-size: 60px
                font-size: 12px
            .progress, .skills
              display: flex
              flex-flow: wrap
              gap: var(--padding)
              span
                margin-inline-end: 5px
                font-size: 12px
              meter
                flex: auto
                min-inline-size: 65px
                block-size: 16px
                &::-webkit-meter-bar
                  block-size: 16px
        section.buttons
          flex-flow: wrap

      /* for Vue pages */
      iframe
        border: none
        flex: 1 0 0px
      button.run
        margin-block-start: calc(-1 * var(--gap))

      /*
       * CONTAINER STYLES
       */
      /* tab view for edit pages */
      &.tab_view > .content
        /* tabs */
        flex: 1 0 auto
        display: flex
        flex-flow: column
        ul.tabs
          display: flex
          flex-flow: wrap-reverse
          font-size: 0px
          li
            border-start-start-radius: var(--button-radius)
            border-start-end-radius: var(--button-radius)
            border-end-start-radius: 0px
            border-end-end-radius: 0px
            display: inline
            inline-size: max-content
            flex: 0 1 auto
            font-size: 12px
            hyphens: none
            img
              margin-inline-end: 5px
              flex: none
            &.disabled, a
              border-inline-start: 1px solid $lightgray
              border-inline-end: 1px solid $darkgray
              border-block-start: 1px solid $lightgray
              border-block-end: 1px solid $darkgray
              border-start-start-radius: var(--button-radius)
              border-start-end-radius: var(--button-radius)
              border-end-start-radius: 0px
              border-end-end-radius: 0px
              block-size: 100%
              background: darken($lightgray, $step) darken-gradient(darken($lightgray, $half-step))
              padding: 5px
              color: black
              text-shadow: 1px 1px 2px lighten($lightgray, $step)
              text-decoration: none
            &:hover a
              background: lighten($lightgray, $half-step) lighten-gradient($lightgray)
            &.ui-tabs-active a
              border-inline-start: 1px solid white
              border-inline-end: 1px solid darken($lightgray, $half-step)
              border-block-start: 1px solid white
              border-block-end: 1px solid lighten($lightgray, $step)
              background: lighten($lightgray, $step) lighten-gradient(lighten($lightgray, $step))
              text-shadow: 1px 1px 2px darken($lightgray, $step)
              cursor: default
        /* panels */
        .panels
          content-visibility: auto
          flex: 1 0 auto
          border-inline-start: 1px solid white
          border-inline-end: 1px solid darken($lightgray, $half-step)
          border-block-end: 1px solid darken($lightgray, $half-step)
          padding: var(--padding)
          background: lighten($lightgray, $step)
          display: flex
          flex-flow: column
          section.content
            flex: 1 0 auto
            display: flex
            flex-flow: column
            gap: var(--padding)
            .tutors .images .image
              margin-inline: 2px
              margin-block: 0px
              background-color: white
            section.list
              border: 1px solid darken($lightgray, $step)
              > .table :is(.thead, .tfoot) .tr
                background: darken($lightgray, $half-step) darken-gradient($lightgray)
                color: black
                text-shadow: 1px 1px 2px white
            > section.panel
              border: 1px solid darken($lightgray, $step)
            form section.panel
              border: none
